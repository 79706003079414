import { render, staticRenderFns } from "./ka-user-cv-show-page-experience.vue?vue&type=template&id=47b52676&scoped=true&"
import script from "./ka-user-cv-show-page-experience.vue?vue&type=script&lang=js&"
export * from "./ka-user-cv-show-page-experience.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-cv-show-page-experience.vue?vue&type=style&index=0&id=47b52676&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b52676",
  null
  
)

export default component.exports