<template>
    <div class="certificate">
        <h5>{{ data.name }}</h5>
        <p v-if="data.description" class="description">
            {{ data.description }}
        </p>
    </div>
</template>

<script>
export default {
    props: ["data"]
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.certificate {
    h5 {
        font-size: 1.25rem;
        line-height: 1.25;
        color: $koderia-gray-800;
        font-weight: 600;
    }

    .description {
        font-size: 0.875rem;
        line-height: 1.75rem;
        margin-top: 1rem;
        color: $koderia-gray-800;
    }
}
</style>
