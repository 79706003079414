<template>
    <v-chip v-bind="$attrs" v-on="$listeners" :class="className" label :ripple="false">
        <slot></slot>
    </v-chip>
</template>

<script>
const AVAILABLE_APPEARANCE = ["primary", "secondary", "primary-small", "secondary-small", "outline", "outline-small"];

export default {
    props: {
        appearance: {
            type: String,
            default: "primary",
            validator: value => AVAILABLE_APPEARANCE.indexOf(value) !== -1
        }
    },
    computed: {
        className() {
            let className = "kod-tech-chip";

            switch (this.appearance) {
                case "primary":
                    className += " kod-tech-chip--primary";
                    break;
                case "secondary":
                    className += " kod-tech-chip--secondary";
                    break;
                case "outline":
                    className += " kod-tech-chip--outline";
                    break;
                case "secondary-small":
                    className += " kod-tech-chip--secondary small";
                    break;
                case "primary-small":
                    className += " kod-tech-chip--primary small";
                    break;
                case "outline-small":
                    className += " kod-tech-chip--outline small";
                    break;
            }

            return `${className} ${this.class}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.kod-tech-chip {
    user-select: none;
    font-size: 12px;
    line-height: 1.75;
    padding: 0.125rem 0.5rem !important;
    border-radius: 0.25rem !important;

    &:hover {
        &:before {
            opacity: 0 !important;
        }
    }

    @media print {
        font-size: 0.75em !important;
        line-height: 1 !important;
        height: 20px !important;
        padding: 0 0.5rem !important;
    }
}

.kod-tech-chip--primary {
    color: $kod-green;
    background: $kod-light-green-accent !important;
}

.kod-tech-chip--secondary {
    color: $koderia-gray-800;
    background: $koderia-gray-100 !important;
}

.kod-tech-chip--outline {
    color: $koderia-gray-800;
    background: transparent !important;
    border: 1px solid $koderia-gray-100 !important;
    transition: all 0.2s ease;

    &:hover {
        background: $koderia-gray-100 !important;
    }
}

.small {
    font-size: 0.65rem;
    line-height: 1.25rem;
}
</style>
