<template>
    <div class="education-wrapper">
        <div><!-- DO NOT REMOVE (PRINT) --></div>
        <div class="education" :class="{ 'break-inside-avoid': isDescriptionShort }">
            <div class="break-inside-avoid">
                <h5>{{ schoolTitle }}</h5>
                <div class="education__meta">
                    <div>{{ schoolLine }}</div>
                    <div class="education__meta-date">
                        <div v-if="data.startYear && data.endYear">{{ data.startYear }} &ndash; {{ data.endYear }}</div>
                        <div v-else-if="data.startYear">{{ data.startYear }}</div>
                        <div v-else-if="data.endYear">{{ translateDateTo(data.endYear) }}</div>
                    </div>
                </div>
            </div>

            <div v-if="this.data.description" class="description">
                <p>{{ this.data.description }}</p>
            </div>

            <!-- <div v-if="descriptionLines" class="description">
                <p v-for="(line, idx) in descriptionLines" :key="idx">
                    <template v-if="dashPunctuationCharCodes.includes(line[0].charCodeAt(0))">
                        <span style="padding-right: 0.75rem;">•</span>
                        <span>{{ line.slice(1).trim() }}</span>
                    </template>
                    <template v-else>
                        <span style="padding-right: 0.75rem;">•</span>
                        <span>{{ line.trim() }}</span>
                    </template>
                </p>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: ["data", "language"],
    computed: {
        dashPunctuationCharCodes() {
            return [
                45, // -
                6150,
                8211,
                8208,
                8209,
                8212,
                8226 // •
            ];
        },
        isDescriptionShort() {
            if (!this.data.description) {
                return true;
            }
            const length = this.data.description.length;
            return (length || 0) < 256;
        },
        schoolTitle() {
            const out = [];

            if (this.data.department) {
                out.push(`${this.data.department}`);
            }

            if (out.length > 0 && this.data.grade) {
                out.push(`(${this.data.grade})`);
            } else {
                out.push(this.data.grade);
            }

            return out
                .filter(Boolean)
                .join(" ")
                .trim();
        },
        schoolLine() {
            return this.data.school || ''
        },
        descriptionLines() {
            if (!this.data.description) {
                return null;
            }

            return this.data.description
                .split(/\r?\n/)
                .map(l => l.trim())
                .filter(Boolean);
        },
        translateDateTo(value) {
            if (value === "dnes" && (this.language === "en" || this.language === "eng")) {
                return "present";
            }

            return value;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.education {
    h5 {
        font-size: 1.25rem;
        line-height: 1.25;
        color: $koderia-gray-800;
        font-weight: 600;
    }
}

.education__meta {
    font-size: 0.875rem;
    line-height: 1.75rem;
    width: 100%;

    color: $koderia-gray-500;
    margin-top: 0.5rem;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @include -lg {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

.education__meta-date {
    flex-shrink: 0;
    margin-left: 1rem;

    @include -lg {
        margin-left: 0rem;
    }
}

.description {
    max-width: 660px;
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.625rem;

    p {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
}
</style>
