import { api } from "./config";

async function fetchOtherUserCv(id, pToken, token) {
    // const query = pToken ? `?p=${pToken}` : ''
    const params = new URLSearchParams()

    if (pToken) {
        params.append('puppeteer-token', pToken)
    }

    if (token) {
        params.append('cv-password', token)
    }

    const query = params.toString() ? `?${params.toString()}` : ''

    const { data } = await api.get(`/candidates/cv/${id}${query}`)

    return data
}

async function fetchSampleCv() {
    const { data } = await api.get('/candidates/cv/sample')

    return data
}

export {
    fetchOtherUserCv,
    fetchSampleCv
}
