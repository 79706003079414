<template>
  <div>
    <nav class="tw-text-black tw-w-full" id="kodAppBar">
      <div class="tw-mx-auto tw-px-4 lg:tw-px-8">
        <div class="tw-flex tw-justify-between tw-h-16 lg:tw-h-20">
          <div class="tw-flex">
            <div class="tw-flex tw-items-center tw-flex-shrink-0">
              <router-link :to="{ name: 'Home', params: { locale: appLocale } }">
                <k-logo class="tw-h-10 sm:tw-h-12 tw-object-left" />
              </router-link>
            </div>
          </div>

          <div class="tw-flex tw-items-center">
            <div class="tw-hidden lg:tw-flex lg:tw-items-center tw-space-x-2">
              <router-link v-if="myCv" :to="{ name: 'profile-cv', params: { locale: appLocale } }">
                <k-button color="secondary" custom-class="tw-py-1 tw-px-4">
                  <k-icon name="user_edit" size="18" class="tw-mr-2" />
                  {{ $t('global.actions.edit') }}
                </k-button>
              </router-link>

              <k-button
                v-if="myCv"
                color="secondary"
                custom-class="tw-py-1 tw-px-4"
                :loading="isGeneratingCv"
                :disabled="isGeneratingCv"
                @click="$emit('generate-pdf')"
              >
                <k-icon name="pdf" size="18" class="tw-mr-2" />
                {{ $t('profile.actions.send_cv_by_email') }}
              </k-button>
            </div>
          </div>

          <div v-if="myCv" class="tw-flex tw-items-center xl:tw-hidden">
            <v-btn icon @click="menuDrawer = true">
              <k-icon name="menu" class="tw-text-gray-700" />
            </v-btn>
          </div>
        </div>

        <v-expand-transition>
          <div v-if="isGeneratingCv" class="tw-text-gray-500 tw-text-sm tw-leading-relaxed tw-text-right">
            <p v-html="$t('profile.descriptions.cv_generation_in_progress', { email: userEmail })" />
          </div>
        </v-expand-transition>
      </div>
    </nav>

    <v-navigation-drawer v-model="menuDrawer" :right="true" app floating temporary :width="424" class="tw-z-20">
      <div class="tw-py-4 tw-px-6">
        <div class="tw-flex tw-items-center tw-justify-between">
          <h5>{{ $t('navigation.titles.menu') }}</h5>
          <v-btn icon @click="menuDrawer = false" class="tw-text-black">
            <k-icon name="close" />
          </v-btn>
        </div>

        <div class="tw-mt-4">
          <k-button
            v-if="!!profile"
            color="secondary"
            class="tw-w-full"
            :loading="isGeneratingCv"
            :disabled="isGeneratingCv"
            @click="$emit('generate-pdf')"
          >
            <k-icon name="pdf" size="18" class="tw-mr-2" />
            {{ $t('profile.actions.send_cv_by_email') }}
          </k-button>
        </div>

        <v-expand-transition>
          <div v-if="isGeneratingCv" class="tw-mt-4 tw-text-gray-500 tw-text-sm tw-leading-relaxed">
            <p v-html="$t('profile.descriptions.cv_generation_in_progress', { email: userEmail })" />
          </div>
        </v-expand-transition>

        <div class="tw-mt-6 tw-flex tw-flex-col tw-space-y-4" v-if="myCv">
          <div class="tw-max-w-full tw-h-px tw-bg-gray-300">
            <!-- Divider -->
          </div>

          <router-link :to="{ name: 'profile-cv' }">
            <k-button color="secondary">
              {{ $t('global.actions.edit') }}
            </k-button>
          </router-link>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import KLogo from '@web/components/core/k-logo'
import { getRouteLocale } from '@web/constants/language'
import KButton from '@web/components/core/k-button'
import { mapGetters } from 'vuex'

export default {
  components: { KButton, KLogo },
  props: {
    isGeneratingCv: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuDrawer: false
    }
  },
  computed: {
    ...mapGetters({
      profile: 'USER/getUserProfile',
      userEmail: 'AUTH/getAuthUserEmail'
    }),
    appLocale() {
      return getRouteLocale()
    },
    myCv() {
      return this.$route.params.id === this.profile?.id
    }
  }
}
</script>

<style lang="scss" scoped>
#kodAppBar {
  & > div:first-child {
    max-width: 64rem;
  }
}

@media print {
  #kodAppBar {
    display: none !important;
    visibility: hidden !important;
  }
}
</style>
