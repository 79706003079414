<template>
    <div class="reference">
        <h5>{{ data.name }}</h5>
        <div class="reference__meta">
            <a v-if="validURL(data.url)" :href="computedUrl" target="_blank" rel="noopener">{{ data.url }}</a>
            <span v-else>{{ data.url }}</span>
        </div>
        <p class="reference__description">
            {{ this.data.description }}
        </p>
    </div>
</template>

<script>
export default {
    props: ["data"],
    methods: {
        validURL(str) {
            // eslint-disable-next-line
            var regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            if (!regex.test(str)) {
                return false;
            } else {
                return true;
            }
        }
    },
    computed: {
        computedUrl() {
            const { url } = this.data || {};

            if (url && this.validURL(url)) {
                if (String(url).startsWith("http://") || String(url).startsWith("https://")) {
                    return url;
                } else {
                    return "//" + url;
                }
            }

            return null;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.reference {
    h5 {
        font-size: 1.25rem;
        line-height: 1.25;
        color: $koderia-gray-800;
        font-weight: 600;
    }
}

.reference__meta {
    margin-top: 0.5rem;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    a,
    span {
        font-size: 0.875rem;
        line-height: 1.75rem;
        color: $koderia-gray-800;
    }
}


.reference__description {
    color: $koderia-gray-800;
    font-size: 0.875rem;
    line-height: 1.75rem;
    margin-top: 1rem;
}
</style>
