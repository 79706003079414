<template>
  <ka-technologies :language="language" :used-technologies="data" :is-print="isPrint" />
</template>

<script>
import KaTechnologies from '@web/components/ka-technologies'

export default {
  components: {
    KaTechnologies
  },
  props: ['data', 'language', 'isPrint']
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

h6,
::v-deep h6 {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.reference__meta a {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.25rem;
  color: #0ea66d;
}

.reference__description {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
}
</style>
