<template>
  <div class="technologies">
    <div
      class="break-inside-avoid technologies__section"
      v-for="(cat, catIndex) in sortedUsedTechCategories"
      :key="catIndex"
    >
      <span>
        <h5>{{ $t('global.expertiseTypes.' + cat.value) }}</h5>

        <div class="tw-mt-4 tech-wrapper">
          <k-chip v-for="tech in cat.data" :key="tech" appearance="secondary" class="tw-uppercase">
            {{ tech }}
          </k-chip>

          <k-chip
            v-if="cat.shouldBeHidden"
            @click="changeCategoryShow(cat.value, !cat.showAll)"
            style="cursor: pointer"
            appearance="secondary"
          >
            ...
          </k-chip>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
// PRIDAT ADEKVATNY PLAT PRE ANJ PO CV
import { mapGetters } from 'vuex'
import KChip from '@web/components/core/k-chip'

export default {
  components: { KChip },
  props: {
    language: {
      type: String,
      default: 'sk'
    },
    usedTechnologies: {
      type: Array,
      default: () => []
    },
    isPrint: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showTech: {}
    }
  },
  computed: {
    ...mapGetters('ENUMS', {
      expertises: 'getExpertises',
      expertiseTypes: 'getExpertiseTypes'
    }),
    usedTechCategories() {
      const allCategories = (this.usedTechnologies || []).map(({ type }) => type)

      return [...new Set(allCategories)]
    },
    sortedUsedTechCategories() {
      const usedCategories = this.expertiseTypes
        .filter(({ value }) => this.usedTechCategories.find(usedCategory => usedCategory.value === value))
      const categoriesWithData = usedCategories.map(category => ({
        ...category,
        data: this.getAllUsedTechnologiesInCategory(category.value)
      }))

      return categoriesWithData.map(category => {
        const shouldBeHidden = category.data.length > 6 && !this.isPrint
        const shouldShow = category.value in this.showTech ? this.showTech[category.value] : !shouldBeHidden

        return {
          ...category,
          data: shouldShow ? category.data : category.data.slice(0, 6),
          shouldBeHidden,
          showAll: shouldShow
        }
      })
    }
  },
  methods: {
    getAllUsedTechnologiesInCategory(category) {
      return this.usedTechnologies.filter(technology => technology.type.value === category).map(technology => technology.name)
    },
    changeCategoryShow(category, value) {
      this.showTech = {
        ...this.showTech,
        [category]: value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.technologies__section {
  @media print {
    width: 100%;
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

.technologies {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;

  @include lg {
    grid-template-columns: 1fr 1fr;
  }

  @media print {
    display: block;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.25;
    color: $koderia-gray-800;
    font-weight: 600;
  }

  .tech-wrapper {
    display: flex;
    flex-wrap: wrap;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    & > * {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
