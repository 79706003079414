<template>
    <div class="description">{{ data.language.name }} &ndash; {{ data.level.displayString }}</div>
</template>

<script>
export default {
    props: ['data'],
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.description {
    font-size: 0.875rem;
    line-height: 1.75rem;
}
</style>
